<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner2.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{ menuType == 1 ? '熔融结晶技术' : menuType == 2 ? '静态板式熔融结晶器' : '熔融结晶成套设备' }}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? '熔融结晶技术' : menuType == 2 ? '静态板式熔融结晶器' : '熔融结晶成套设备' }}</div>
      </div>
    </div>
    <div class="page-menu d-flex-center">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">技术介绍</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">核心设备</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">成套装置</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">技术原理</div>
      <div class="page-text mb-15">熔融结晶是一种根据待分离物质之间凝固点不同而实现物质分离提纯的方法，是一种低能耗的有机物清洁分离工艺，可高效分离沸点接近的烃类、同分异构体和热敏材料等特殊物系，超纯物质的制备是熔融结晶的特有优势。熔融结晶通过降温结晶、升温发汗和加热熔融三个过程的操作得到高纯产品。</div>
      <div class="preview-image mb-40"><img class="page-img" v-image-preview  v-animate-on-intersect src="../../assets/img/product/item2-1.png" alt=""></div>
      <div class="product-item-title">典型应用</div>
      <el-row class="flex-warp mb-40 radius-10" justify="space-between" type="flex"  v-animate-on-intersect>
        <el-col :lg="13" :md="13" :sm="13" :xl="13" :xs="24">
          <div class="preview-image"><img v-image-preview class="box-img" style="height: 100%" src="../../assets/img/product/item2-2.png"></div>
        </el-col>
        <el-col :lg="11" :md="11" :sm="11" :xl="11" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><div class="dot"></div>将精馏到95%纯度的碳酸亚乙烯酯（VC）提纯到99.99%</div>
            <div class="dot-list"><div class="dot"></div>将99%的氟代碳酸乙烯酯（FEC）提纯到99.95%</div>
            <div class="dot-list"><div class="dot"></div>将丙烯酸中阻聚剂含量从200ppm降低至20ppm</div>
            <div class="dot-list"><div class="dot"></div>分离对甲酚/间甲酚的混合物</div>
            <div class="dot-list"><div class="dot"></div>将工业级苯甲酸提纯为食品级苯甲酸</div>
            <div class="dot-list"><div class="dot"></div>将工业级苯甲酸卞酯提纯到香精香料级</div>
            <div class="dot-list"><div class="dot"></div>高纯氯乙酸制备</div>
            <div class="dot-list"><div class="dot"></div>生产电子级磷酸</div>
            <div class="dot-list"><div class="dot"></div>提纯萘油 苯酚 碳酸乙烯酯</div>
            <div class="dot-list"><div class="dot"></div>生产色谱级乙腈</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">技术特点</div>
      <div class="img-list mb-40"  v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>节能：</span>熔融热仅是汽化热的1/7~1/3，熔融结晶综合能耗仅为精馏能耗的10%~30%</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>低温操作：</span>常压、低温操作，非常适用于热敏性物质、稀溶液、高沸点物质的分离提纯</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>不引入溶剂：</span>熔融结晶不需外加溶剂，即避免了溶剂对产品的污染，又减少了溶剂回收过程</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>适用于特种物系：</span>适用于沸点相近、同分异构体、手性物质等用常规的精馏极难分离体系</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>产品纯度高：</span>熔融结晶产品纯度一般可达到99.9%以上，最高可到99.999%</div></div>
      </div>
    </div>
    <div v-else-if="menuType == 2" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40"  v-animate-on-intersect>板式熔融结晶器为静态层熔融结晶单元的核心设备，规格从1L到30m3，无论应用规模大小，敏恒科技都能满足要求。我们将根据您的物料组成和分离要求进行设计，让您的工艺与最佳的设备性能相匹配。</div>
      <div class="product-item-title">结构</div>
      <div class="page-text">板式熔融结晶器由外箱体、结晶板和支撑板组成，无传动部件，静态间歇批次操作，免维护、易操作，可胜任各种化学品的高纯度生产要求。结构组成：</div>
      <el-row class="flex-warp mb-40" v-animate-on-intersect justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">外箱</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-1.png" alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">结晶版</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-2.png" alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">结晶板组</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-3.png" alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">组装成品</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-4.png" alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">设计特征</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">结晶板为高强度薄板，设备重量轻，安装所需基础负荷小</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">独特的流道设计，确保结晶板温度场均匀，结晶板不同位置温差＜0.1℃，有效保证分离效率</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">结晶板之间设置支撑板，强化传热的同时使支撑晶层发汗时不塌陷，提升发汗效率</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">规整的长方体外形，空间利用效率高，安装、检修和维护极其方便</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">匹配结晶要求的外箱设计，无结晶死区，放料方便，出料彻底</div>
      </div>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>敏恒科技致力于提供最高效和最可靠的成套设备，以保证您的结晶器得到最佳的使用性能。为了获得最佳的使用性能，从设计与工艺相匹配的结晶器、到配套的公用工程和氮气保护系统，我们以严谨、科学的原则解决所有设计和制造方面的问题，实现系统的完整集成。</div>
      <div class="product-item-title">成套设备组成</div>
      <div class="page-text">熔融结晶成套设备由结晶器、储运系统、公用工程、氮气保护系统、管道阀门、电气仪表、控制系统、梯子平台、保温伴热等二级子单元组成，根据规模大小分可为撬装设备或现场安装系统。撬装设备将在生产车间完成组装和测试，然后分拆运输到客户现场进行二次组装并调试；现场安装系统将在客户现场完成组装和调试。</div>
      <el-row class="flex-warp mb-40" v-animate-on-intersect justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">一级结晶成套撬装设备</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/item4-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">三级结晶现场安装系统</div>
             <div class="preview-image"><img class="page-img" src="../../assets/img/product/item4-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">成套设备的优点</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">尽管结晶器的设计是核心，但辅助系统对于设备的高效稳定运行也非常关键，成套设备最大程度利用了敏恒在熔融结晶设备辅助系统方面的专业知识和丰富经验，可以确保客户设备投入运营即获得最佳使用性能</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">撬装设备或现场安装系统服务将最大限度地的节省客户投入项目的人力资源和精力，客户可将其相关资源用在最能产生效益的地方</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">最大程度避免各供应商单⼀供货模式造成的设计与硬件匹配连贯性不⾜、多界⾯交接复杂、交付不确定、项⽬建设⼯期延长等问题</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "熔融结晶",
  data(){
    return{
      menuType:1
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
